/**
 * It takes two strings, compares them, and returns the difference between them
 * @param prev - The previous string
 * @param curr - The current string of the DOM
 * @returns The difference between the previous and current DOM.
 */
const getDiff = (prev, curr) => {
  const prevArr = prev.split(' ');
  const prevArrRev = prev.split(' ').reverse();
  const currArr = curr.split(' ');
  const currArrRev = curr.split(' ').reverse();

  let leftIndexes = [];
  let rightIndexes = [];

  // Left right check
  prevArr.forEach((val, index) => {
    if (val === currArr[index]) {
      if (index === leftIndexes[leftIndexes.length - 1] + 1 || index === 0) {
        leftIndexes.push(index);
      }
    }
  });

  // Right left check.
  prevArrRev.forEach((val, index) => {
    if (val === currArrRev[index]) {
      if (
        index === rightIndexes[rightIndexes.length - 1] + 1 ||
        index === 0
      ) {
        rightIndexes.push(index);
      }
    }
  });

  const domDiff = (() => {
    const build = [];
    let start = false;
    let completed = false;
    currArr.forEach((val, index) => {
      if (completed || index <= leftIndexes[leftIndexes.length - 1]) return;
      if (
        !start &&
        leftIndexes.indexOf(index) === -1 &&
        (val.indexOf('&lt;') > -1 || val.indexOf('<') > -1)
      ) {
        start = true;
        build.push(val);
        return;
      }
      if (currArrRev[rightIndexes.length - 1] === val) {
        completed = true;
        return;
      }

      if (val.indexOf('&gt;.') > -1 || val.indexOf('>.') > -1) {
        val = val.replace('&gt;.', '&gt;');
        val = val.replace('>.', '>');
      }

      build.push(val);
    });

    return build;
  })();

  return domDiff.join(' ');
}